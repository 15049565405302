<template>
  <Modal
    :modalId="`modal_fluxo`"
    modalTitle="Fluxo"
    :load="false"
    :typeLg="'modal_lg'"
    @closeModal="closeModal"
  >
    <div class="d-flex align-items-center justify-content-center">

      <half-circle-spinner
        v-if="!img"
        :animation-duration="1000"
        :size="100"
        color="#f38235"
        class="mx-2 my-3"
      />
      <img v-else :src="returnImg(img)" class="img">
      <!-- {{img.image64}} -->
      <!-- {{returnImg(img)}} -->
    </div>
  </Modal>
</template>

<script>
import Modal from '../../modal/modalDefault.vue'
import { HalfCircleSpinner } from "epic-spinners";
export default {
  props: ['img'],
  components: {
    Modal,
    HalfCircleSpinner
  },
  data() {
    return {}
  },
  methods: {
    closeModal () {
      $("#modal_fluxo").modal("hide");
    },
    returnImg (data) {
      return `data:${data.mimeType};base64,${data.image64}`;
    }
  },
}
</script>

<style scoped>
.img {
  width: 100%;
}
</style>
