<template>
  <div>
    <h1 class="title" v-if="service">{{ service.name }}</h1>
    <div class="d-flex mb-5 ">
      <div
        :class="!serviceParametrization ? 'col-md-6 p-0 mbClass' : 'mbClass'"
      >
        <div class="col-md-12">
          <ValidationObserver ref="field" v-if="serviceParametrization">
            <service
              :filtersParamsProprietário="filtersParamsProprietário"
              :filtersParamsComprador="filtersParamsComprador"
              :tagsDefault="tagsDefault"
              :textInfo="textInfo"
              :sendError="sendError"
            />
          </ValidationObserver>
          <ValidationObserver ref="fields" class="row" v-else>
            <div
              v-for="(field, index) in tags"
              :key="index"
              :class="
                field.fieldName !== 'Chassi' ? 'my-1 p-1 col-6' : 'my-1 p-1 col'
              "
            >
              <ValidationProvider v-slot="{ classes }" tag="div">
                <div class="d-flex align-items-center">
                  <label class="mb-2 label_default">{{
                    field.fieldName.toUpperCase()
                  }}</label>
                  <div v-if="field.isRequired" class="mx-2 requeridLabel">
                    *
                  </div>
                </div>
                <div>
                  <the-mask
                    type="text"
                    :class="
                      `form-control getValue  ${concatName(field.fieldName)}`
                    "
                    :mask="
                      field.requiredMask
                        ? field.fieldMask
                        : returnItens(field.fieldLenght)
                    "
                    :placeholder="field.fieldMask"
                    :name="field.fieldName"
                    :tokens="hexTokens"
                    :required="field.isRequired"
                    @blur.native="
                      focusFieldValidation(
                        $event,
                        field,
                        concatName(field.fieldName)
                      )
                    "
                  />
                </div>
              </ValidationProvider>
            </div>
          </ValidationObserver>
        </div>
        <!-- <uploadFileVue v-if="service && service.type === 7" @openModal="openModal"  /> -->
        <div class="col-md-12 p-0 borderBt mt-3" v-if="service">
          <button class="btn_default" @click="sendRequest" :disabled="load">
            <div v-if="!load">
              Seguinte
              <img src="@/assets/image/icones/arrowNext.png" alt="arrowNext" />
            </div>
            <half-circle-spinner
              v-else
              :animation-duration="1000"
              :size="30"
              color="#fff"
            />
          </button>

          <button
            class="btn_default"
            v-if="itemImage.length > 0"
            @click="openModalFluxo"
          >
            Fluxo
          </button>
        </div>
      </div>
      <documentRequeridVue
        class="col-md-6"
        :isUpload="false"
        :documents="documents"
        v-if="documents.length > 0"
      />
    </div>
    <modalFluxo :img="imgFluxo" />
    <!-- <modalFile @next="sendModalNext" @sendFile="sendFiles" :load="loadFile" :numberItens="documents.length"/> -->
  </div>
</template>

<script>
import HTTP from "@/api_system";
import uploadFileVue from "./upload/uploadFile.vue";
import documentRequeridVue from "./document/documentRequerid.vue";
import { TheMask } from "vue-the-mask";
import { HalfCircleSpinner } from "epic-spinners";
import { mapGetters, mapMutations, mapActions } from "vuex";
import service from "./service.vue";
import modalFile from "./upload/modalUploadFiles.vue";
import { HubConnectionBuilder } from "@aspnet/signalr";
import modalFluxo from "./modal/fluxoService.vue";
export default {
  components: {
    TheMask,
    uploadFileVue,
    documentRequeridVue,
    HalfCircleSpinner,
    service,
    modalFile,
    modalFluxo
  },
  data() {
    return {
      hexTokens: {
        G: { pattern: /[0-9a-zA-Z]/, transform: v => v.toLocaleUpperCase() }, // numero com Letras Mai
        "#": { pattern: /\d/ }, // Numero
        X: { pattern: /[0-9a-zA-Z]/ }, // Numeros e letras
        A: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
        a: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
        T: { pattern: /[0-9a-zA-Z-@]/ },
        "!": { escape: true }
      },

      service: undefined,
      documents: [],
      tags: [],
      fieldvalues: [],
      vehiclePlateValue: "",
      load: false,
      sendError: false,
      allServiceParams: [],
      serviceParametrization: false,
      tagsDefault: [],
      filtersParamsProprietário: [],
      filtersParamsComprador: [],
      textInfo: "",
      uploadFIles: false,
      checkService: undefined,
      loadFile: false,
      orderId: "",
      itemImage: [],
      imgFluxo: ""
    };
  },
  mounted() {
    this.getService();
  },
  computed: {
    ...mapGetters([
      "getDataService",
      "getOrderServiceId",
      "getOrderId",
      "getUploadFilesItens"
    ])
  },
  methods: {
    ...mapActions(["sendPedidos", "getImageFluxo"]),
    ...mapMutations([
      "set_pedido",
      "changeOrderId",
      "changeOrderServiceId",
      "setUploadFilesItens"
    ]),

    returnItens(item) {
      let forItens = [];
      for (let i = 0; i <= item; i++) {
        forItens.push("T");
      }
      return forItens.join("");
    },

    openModalFluxo() {
      $("#modal_fluxo").modal("show");
      this.openImage(this.itemImage[0].id);
    },

    openImage(id) {
      this.getImageFluxo(id)
        .then(res => {
          this.imgFluxo = res.data.result;
        })
        .catch(err => {
          console.log(err);
        });
    },

    openModal(nameModal, valueBool) {
      this.getValues();

      if (this.sendError) return;

      this.uploadFIles = valueBool;
      // if(!this.checkService) {
      //   this.sendRequest()
      // }

      $("#modal_UploadFiles").modal("show");
    },

    stringEmail(value) {
      return value.indexOf("@") > -1;
    },

    getFilterParams(item) {
      const filtersParams = item.filter(
        item =>
          item.serviceParametrizationId == null &&
          item.fieldName !== "Logradouro"
      );
      const filtersParamsProprietário = item.filter(
        item =>
          item.serviceParametrizationId == 33 &&
          item.fieldClass != "fieldDadosdoProprietario"
      );
      const filtersParam = item.filter(
        item =>
          item.serviceParametrizationId == 34 &&
          item.fieldClass !== "fieldDadosdoComprador"
      );
      const message = item.filter(item => item.fieldClass == "Mensagem");

      this.textInfo = message;
      this.tagsDefault = filtersParams;
      this.filtersParamsProprietário = filtersParamsProprietário;
      this.filtersParamsComprador = filtersParam;
    },

    getValues() {
      const values = [];
      const doc = document.querySelectorAll(".getValue");
      this.sendError = false;
      doc.forEach(({ value, name, required }) => {
        // console.log(required)
        if (name == "Placa") {
          this.vehiclePlateValue = value;
        }

        if(name.split(' ')[0] === 'Cpf' && value.length > 0 && value.length < 14 ) {
          this.toast("CPF inválido", this.$toast.error);
          this.sendError = true;
            setTimeout(() => {
              this.sendError = false;
            }, 1000);
          return
        }
        // if(this.vehiclePlateValue.length < 8) {
        //   console.log(name)
        //   this.toast("Placa inválida", this.$toast.error);
        //   return
        // }

        if (required && value == "") {
          this.sendError = true;
          setTimeout(() => {
            this.sendError = false;
          }, 1000);
          return;
        }

        if (value == "") {
          return;
        }
        values.push(`${name}: ${value}`);
      });
      // console.log(values)
      if(this.vehiclePlateValue && this.vehiclePlateValue.length < 8) {
        this.toast("Placa inválida", this.$toast.error);
        this.sendError = true;
          setTimeout(() => {
            this.sendError = false;
          }, 1000);
        return
      }

      if (this.sendError ) {
        this.toast("Preencha todos os campos obrigatorios.", this.$toast.error);
      }
      this.fieldvalues = values;
    },

    async sendRequest() {
      this.getValues();
      if (this.sendError) {
        return;
      }

      this.load = true;
      // await this.getCreateService(this.getDataService.id);
      // getOrderId
      const jsonData = {
        serviceId: Number(this.$route.params.id),
        orderId: this.getOrderId,
        vehiclePlate: this.vehiclePlateValue,
        dataTransaction: this.fieldvalues
      };
      await this.ServiceDispatcherCreate(jsonData);
    },

    async ServiceDispatcherCreate(jsonData) {
      this.load = true;
      try {
        const { data } = await HTTP.post(
          `services/app/Order/CreateFromDispatcher`,
          jsonData,
          {
            headers: {
              Authorization: `bearer ${this.$store.getters.getterToken}`
            }
          }
        );
        const { orderId, orderServiceId } = data.result;
        this.orderId = orderId;
        this.changeOrderId(orderId);
        this.changeOrderServiceId(orderServiceId);
        this.sendUpdatePedido();
      } catch (error) {
        console.error(error);
        this.deletePedidos();
        this.toast(error.response.data.error.message, this.$toast.error);
        this.load = false;
      }
    },

    concatName(item) {
      const itens = item.split(" ");
      if (itens.length > 1) {
        return itens.join("");
      }

      return itens[0];
    },

    focusFieldValidation(event, field, name) {
      const { fieldName, isRequired } = field;
      const { value, classList } = event.target;
      if (value && isRequired) {
        classList.forEach(element => {
          if (element === name) {
            const item = document.querySelector(`.${element}`);
            item.classList.remove("requerid");
          }
        });
      }
    },

    maskValue(item, letter) {
      const itens = item.replaceAll(".", "").replaceAll("-", "");
      let amount = [];
      for (let i = 0; i < itens.length; i++) {
        amount.push(isNaN(itens) ? "X" : "P");
      }
      return amount.join("");
    },

    getService() {
      this.service = this.getDataService;
      this.documents = this.getDataService.documents;
      //
      this.allServiceParams = this.getDataService.parameters;

      this.allServiceParams.forEach(item => {
        const { serviceParametrizationId } = item;
        if (serviceParametrizationId) {
          this.serviceParametrization = true;
          return;
        }
      });

      this.getFilterParams(this.getDataService.parameters);
      if (this.serviceParametrization) return;

      const newTags = this.getDataService.parameters.filter(
        item => item.fieldLenght > 0
      );
      this.tags = newTags;

      const items = this.getDataService.parameters.filter(
        item => item.fieldTag === "<img />"
      );
      this.itemImage = items;
      //console.log(this.getDataService.parameters)
    },

    async sendPedido() {
      this.loadFile = true;
      const jsonParams = {
        orderServiceId: this.getOrderServiceId,
        listPrint: [],
        quantity: ""
      };
      try {
        const result = await this.sendPedidos(jsonParams);
        this.set_pedido(result.data.result);
        this.checkService = result.data.result;
        if (this.service.type === 7) {
          this.sendFiles();
          return;
        }
        this.loadFile = false;
        // if(this.uploadFIles) return
        $("#modal_UploadFiles").modal("hide");
        this.$nextTick(() => {
          this.$router.push({ path: "/finalizar-servico" });
        });
        // this.disabled = false;
      } catch (error) {
        // this.disabled = false;
        this.loadFile = false;
        if (error.response.data) {
          this.toast(error.response.data.error.message, this.$toast.error);
          this.$store.commit("changeOrderId", "");
          setTimeout(() => {
            this.$store.commit("set_pedido", "");
            this.$router.push("/pedidos");
            this.load = false;
          }, 2000);
          return;
        }

        this.toast("Ops algo deu errado", this.$toast.error);
      }
    },

    nextPage() {
      this.$router
        .push({ path: "/finalizar-servico" })
        .then(() => {})
        .catch(() => {});
      $("#modal_UploadFiles").modal("hide");
      this.setUploadFilesItens([]);
    },

    sendModalNext() {
      this.sendRequest();
    },

    async sendFiles() {
      // if(!this.checkService) {
      //   this.sendRequest()
      // }
      const file = [...this.getUploadFilesItens].filter(
        s => s.inputFile != "" && s.name != ""
      );
      if (!file.length) {
        this.nextPage();
        this.load = false;
        return;
      }
      let data = new FormData();

      for (let i = 0; i < file.length; i++) {
        data.append(file[i].name, file[i]);
      }

      try {
        await HTTP.post(
          `services/app/OrderServiceFile/Create?orderServiceId=${this.getOrderServiceId}`,
          data,
          {
            headers: {
              Authorization: `bearer ${this.$store.getters.getterToken}`
            }
          }
        );
        this.nextPage();
        this.load = false;
      } catch (error) {
        console.error(error);
        this.toast(error.response.data.error.message, this.$toast.error);
        this.nextPage();
        this.load = false;
      }
    },

    // async getCreateService(serviceID) {
    //   try {
    //     const { data } = await HTTP.post(
    //       `services/app/Order/CreateOrEdit?serviceId=${serviceID}&orderId=${this.getOrderId}`,
    //       {},
    //       {
    //         headers: {
    //           Authorization: `Bearer ${this.$store.getters.getterToken}`
    //         }
    //       }
    //     );
    //     const { orderId, orderServiceId } = data.result;
    //     this.orderId = orderId;
    //     this.changeOrderId(orderId);
    //     this.changeOrderServiceId(orderServiceId); //!
    //   } catch (error) {
    //     console.error(error);
    //     this.toast(error.response.data.error.message, this.$toast.error);
    //   }
    // },

    async sendUpdatePedido() {
      try {
        const result = await HTTP.post(
          `services/app/OrdersServices/ResultOfApplyOrderService?orderId=${this.orderId}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getterToken}`
            }
          }
        );
        this.set_pedido(result.data.result);
        this.checkService = result.data.result;
        if (this.service.type === 7) {
          this.sendFiles();
          return;
        }
        this.nextPage();
        return;
      } catch (error) {
        this.toast("Ops algo deu errado", this.$toast.error);
      }
    },

    deletePedidos() {
      let message = "Erro ao criar serviço";
      HTTP.post(
        `/services/app/OrdersServices/DiscardOrderServices?orderServiceId=${this.getOrderServiceId}&MessageCancelation=${message}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(response => {
          this.$store.commit("changeOrderId", "");
          setTimeout(() => {
            this.$store.commit("set_pedido", "");
            this.$router.push("/pedidos");
          }, 2000);
        })
        .catch(error => {
          this.toast(
            "Ops algo deu errado serviço não finalizado!",
            this.$toast.error
          );
        });
    }
  },
  watch: {
    sendError(newValue, oldValue) {
      if (newValue) {
        const doc = document.querySelectorAll(".getValue");
        doc.forEach(item => {
          if (item.value == "" && item.required) {
            item.classList.add("requerid");
          }
        });
      }
    }
  }
};
</script>

<style scoped>
* {
  font-style: normal;
  font-weight: 700;
}

.title {
  margin: 24px 0;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}
.containerForms {
  width: 369px;
}
.label_default {
  font-weight: 300;
  font-size: 13px;
  color: #ffffff;
}
.btn_default {
  height: 40px;
  border: none;
  margin-top: 25px;
  width: 100%;
  background: #f38235;
  border-radius: 6px;
  width: 130px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  transition: 0.2s ease-in-out;
  outline: none;
}

.btn_default:hover {
  background: rgba(216, 109, 37, 0.884);
}

.form-control {
  background: #ffffff;
  /* border: 1px solid #98C4E6; */
  border-radius: 6px;
  height: 40px;
  background-color: #f6f6f6 !important;
  font-weight: 500 !important;
  color: #868686;
}

::-webkit-input-placeholder {
  color: #868686;
  opacity: 0.5;
}
.borderBt {
  border-top: 2px solid #3f88c1;
}

.requerid {
  border: 1px solid red;
  color: red;
}
.requeridLabel {
  color: red;
}

.mbClass {
  margin-bottom: 50px;
}
</style>
